const Privacy = () => {
    return (
        <div className="flex w-full justify-center items-center">
            <div className="flex flex-col gap-8 px-5 mt-32 min-[800px]:w-1/2">
                <div className="flex flex-col text-left gap-8">
                    <span className="text-[34px] font-bold">PRIVACY POLICY</span>
                    <div className="flex flex-col gap-4">
                        <span className="text-[14px] italic">[Last Revised: Sept 25, 2023]</span>
                        <span className="text-[14px]">We, at SaudaPro Private Limited (hereafter referred to as "<span className="font-bold">Sauda Pro</span>"), recognize the importance of your privacy and are committed to protecting it. This Privacy Policy outlines our practices concerning the collection, use, and sharing of your information through our website <span className="font-bold">https://sauda.io/</span> (the "Website") and the Sauda Pro mobile application (the "<span className="font-bold">App</span>"). The Website and App, along with any other features, tools, materials, or other services offered from time to time by Sauda Pro are collectively referred to as the "<span className="font-bold">Services</span>". The Services are collectively referred to as the "<span className="font-bold">Platform</span>". References to "<span className="font-bold">we</span>", "<span className="font-bold">our</span>" or "<span className="font-bold">us</span>" or the "<span className="font-bold">Company</span>" shall mean the Platform and/or Sauda Pro. Any references to "<span className="font-bold">you</span>", "<span className="font-bold">your</span>" or "<span className="font-bold">user</span>" shall mean any person or entity using our Platform. We will not use or share your information with anyone except as described in this Privacy Policy.</span>
                        <span className="text-[14px]">This Privacy Policy is a part of and is to be read with the Sauda Pro Terms of Use ("<span className="font-bold">Terms</span>") and our Sauda Pro Content Policy. By using this Platform, you agree to the terms and conditions of this Privacy Policy. You also consent to our use and disclosure of your Personal Information (as defined below) in the manner described in this Privacy Policy. The capitalized words used in this Privacy Policy but not defined herein, shall have the meaning given to such words in the Terms. If you do not agree with the terms and conditions of this privacy policy, please do not use this platform.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">THE INFORMATION WE COLLECT AND HOW WE USE IT</span>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-col w-3/5 gap-4">
                                <div className="flex flex-col gap-2">
                                    <span className="text-[14px] font-bold">Information You Provide to Us</span>
                                    <span className="text-[14px]">We collect information you provide to us directly when you use the Services. This includes:</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Account information:</span>
                                        <span> User ID, mobile phone number. When you use Sauda Pro, you may provide other optional information. We may ask you to select interests to help create a home feed for you or to select clubs to join. You may also provide other information, such as a bio, age, location, or profile picture. This information, which you may remove or alter at any time, helps in tailoring your experience on Sauda Pro. We may ask for such information prior to you creating a username or account to help improve your experience exploring Sauda Pro.</span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Content you submit:</span>
                                        <span> We collect the content you submit to the Services. This includes your posts and comments including saved drafts, audio and videos you broadcast, your messages with other users (e.g., private messages, chats etc), and your reports and other communications with moderators and with us. This content may include, but is not limited to, text, links, images, gifs, audio, and videos.</span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Actions you take:</span>
                                        <span> We collect information about the actions you take when using the Services. This includes your interactions with content, such as voting, saving, hiding, and reporting, as well as interactions with other users, including following, friending, and blocking. It also includes your participation in communities, such as your subscriptions or moderator status.</span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Log Data:</span>
                                        <span> "Log Data" is information that we automatically collect when you use the Platform, whether through the use of cookies, web beacons, log files, scripts, including, but not limited to technical information, such as your mobile carrier-related information, configuration information made available by your web browser or other programs you use to access the Platform, your IP address and your device’s version and identification number.</span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Location information:</span>
                                        <span> We may receive and process information about your location. We may receive location information from you when you choose to share such information on our Services, including by associating your content with a location, or we may derive an approximate location based on your IP address.</span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <div>
                                        <span className="font-semibold">Other information:</span>
                                        <span> AnYou may choose to provide other information to us. For example, we may collect information when you fill out a form, participate in Sauda Pro-sponsored programs, activities or promotions, apply for a job, request customer support, or otherwise communicate with us.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 w-2/5">
                                <span className="text-[14px] font-bold">How We Use It</span>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Provide, maintain, and improve the Services.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Personalize services, content, and features that match your activities, preferences, and settings.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Help protect the safety of Sauda Pro and our users, which includes blocking suspected spammers, addressing abuse, and enforcing our other policies.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Provide, optimize, target, and measure the effectiveness of ads shown on our Services.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Research and develop new services.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Send you technical notices, updates, security alerts, invoices, and other support and administrative messages.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Provide customer service.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you.</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <span>●</span>
                                    <span>Monitor and analyze trends, usage, and activities in connection with our Services.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col text-left gap-10">
                    <span className="text-[34px] font-semibold">DISCLOSURE OF YOUR INFORMATION</span>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                            <span className="text-[24px] font-semibold">Visibility of Content</span>
                            <span className="text-[14px]">Public content—such as comments or posts you make on user profiles or any content you voluntarily disclose on the Platform—is accessible to everyone, including search engines. When you share content you choose to make public, it can be reshared by others. Please be mindful about who you choose to share content with, as people who can see your activity on our Platform can share it with others inside and outside the audience you shared it with. Additionally, interactions like comments or likes on someone else’s post are visible to anyone who can see the content.</span>
                        </div>
                        <div className="flex flex-col gap-4">
                            <span className="text-[24px] font-semibold">Sharing Content with Others</span>
                            <span className="text-[14px]">You select the audience when you share content and communicate using our Platform. For instance, when you share content from our Platform on Facebook, you choose the audience for the post. We have no control over, and shall not be liable for the manner in which those who receive the content via any sharing options available on the Platform use the information.</span>
                        </div>
                        <div className="flex flex-col gap-4">
                            <span className="text-[24px] font-semibold">Sharing with Third Parties</span>
                            <span className="text-[14px]">We do not share sensitive personally identifiable information with any third party without user consent, except in limited circumstances such as:</span>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Legal Compliance and Law Enforcement:</span>
                                <span> When disclosure is requested or required by law, court, or any governmental agency for identity verification or for the prevention, detection, investigation of offenses, in good faith and belief that such disclosure is necessary for enforcing these Terms and complying with applicable laws and regulations.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Sauda Pro Customer Support:</span>
                                <span> To facilitate certain app functionalities by sharing information with our company’s officers and employees. Recipients of such information are required to process this information in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</span>
                            </div>
                        </div>
                        <span>We may share anonymized information with:</span>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Affiliates:</span>
                                <span> To help provide, understand, and improve services.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Advertisers:</span>
                                <span> To select and serve relevant ads to you and others.</span>
                            </div>
                        </div>
                        <span>We may also disclose information to select third parties in circumstances like business transfers or to enforce our terms and other agreements.</span>
                        <span className="text-[24px] font-semibold">Advertising</span>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Third-Party Ad Networks:</span>
                                <span> Please be aware that third-party advertising companies and advertising networks may serve advertisements within our Services. These companies may use information about your visits to this and other websites or applications in order to provide relevant advertisements about goods and services that may interest you.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">User Data and Purpose:</span>
                                <span> We, along with third-party vendors such as Google, use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website and app. The information collected includes, but is not limited to, device IP addresses, device identifiers, and user interactions. This information is used to serve ads based on a user's prior visits to our website or other websites, enhancing user experience and engagement within our Services.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Cookies and Device Identifiers:</span>
                                <span> Our Services use cookies and other device identifiers to collect data, which helps in delivering personalized ads and measuring their effectiveness. This means that users might see ads that are selected based on what the user has viewed on our site.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Opt-out Provisions:</span>
                                <span> Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt-out by visiting the Network Advertising Initiative Opt-Out page or by using the Google Analytics Opt-Out Browser add-on. For further information about opt-out options, please visit <span className="font-bold text-blue-500">Opt-out Link</span>.</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 pl-8">
                            <span>●</span>
                            <div>
                                <span className="font-semibold">Affiliate Advertising:</span>
                                <span> We participate in affiliate advertising and allow affiliate links to be encoded on some of our pages. This means that we may earn a commission when you click on or make purchases via affiliate links, and affiliate partners may use cookies to understand your use of the Services.</span>
                            </div>
                        </div>
                        <span>By incorporating these changes, the advertising section will be more aligned with Google AdMob’s policies, offering clear and concise information to the users regarding the use of their data for advertising purposes.</span>
                    </div>

                    <div className="flex flex-col text-left gap-10">
                        <span className="text-[34px] font-semibold">HOW WE PROTECT YOUR INFORMATION</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Security Measures:</span>
                                <span className="text-[14px]">We employ appropriate security measures to safeguard against unauthorized access, alteration, disclosure, or destruction of data. These include internal reviews of our data collection, storage, and processing practices and security measures, including encryption and physical security measures.</span>
                            </div>
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Data Storage:</span>
                                <span className="text-[14px]">All information gathered is securely stored within our controlled database, protected by a firewall, and access is password-protected and strictly limited.</span>
                            </div>
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Security Limitation Disclaimer:</span>
                                <span className="text-[14px]">Despite our rigorous security measures, no security system is impenetrable. We cannot guarantee absolute security of our database, nor can we assure that the information you supply will not be intercepted while being transmitted to us over the Internet.</span>
                            </div>
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Where we store your personal information</span>
                                <span className="text-[14px]">We store your data on secure servers provided by Amazon Web Services (AWS) and Google Cloud Platform, located both in India and abroad. These providers implement robust security measures to protect your data. For more information on their security and privacy policies, please refer to:</span>
                            </div>
                            <div className="flex flex-row gap-2 pl-8">
                                <span>●</span>
                                <div className="flex flex-row gap-2">
                                    <span className="font-semibold">AWS:</span>
                                    <span className="text-blue-500">Security</span>
                                    <span>  | </span>
                                    <span className="text-blue-500">Privacy Policy</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 pl-8">
                                <span>●</span>
                                <div className="flex flex-row gap-2">
                                    <span className="font-semibold">Google Cloud Platform:</span>
                                    <span className="text-blue-500">Security</span>
                                    <span>  | </span>
                                    <span className="text-blue-500">Privacy Policy</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col text-left gap-10">
                        <span className="text-[34px] font-semibold">YOUR RIGHTS</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Information Removal</span>
                                <span className="text-[14px]">If you want to completely remove all your information from our databases, please email us at <span className="font-bold">remove@sauda.io</span> along with the mobile phone number associated with the account. After validation of the ownership of your mobile number, we will delete all contact information from our databases including phone, email (if supplied), devices associated with your account. However we may retain anonymized history of your actions in the App e.g. clubs you have followed, chat messages you have sent, your historical click stream. You can restore your Sauda Pro account if it was accidentally or wrongfully deactivated for upto 90 days after deactivation.</span>
                                <span className="text-[14px]">After you delete your account, we retain certain data including personal information generated from your account for various regulatory and compliance purposes for a limited period of time. We may also retain aggregated information on deletion of your account.</span>
                                <span className="text-[14px]">You may opt out from unwanted marketing communications from us by following the instructions in the message. However, until your account is deleted you will continue to receive all system marketing communication. Under Section 5(6) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 ("<span className="font-bold">Rules</span>"), you have a right to ask us to review, correct and amend the information we collect about you at any point in time. Under Section 5(7) of the Rules, you also have the right to revoke your consent to the collection of your information going forward. However, please note that revoking your consent may negatively affect your use of the Platform. For instance, we need access to your mobile device’s media folder and camera so that you can click pictures from your mobile device and post them on the Platform, which functionality will not be available to you if you do not provide us such access. You may also ask us not to process your personal data for marketing purposes. You can exercise any of these rights at any time by contacting us at <span className="font-bold">opt-out@sauda.io</span>. However, we require a reasonable period of 30 (thirty) days to comply with any of your requests.</span>
                            </div>
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Data Retention</span>
                                <span className="text-[14px]">We do not retain your Sensitive Personal Information (defined below in this paragraph) for longer than is required for the purposes for which the information may be lawfully used. For any other content, we will entertain your request for deletion, however, there is a strong possibility that copies of any public content will be retained indefinitely in our systems, including in cached and archived pages of the Platform, or if other users have copied or saved that information. In addition, due to the nature of the internet, copies of your content, including content that you have removed or deleted from your account, may also exist elsewhere on the Internet and be retained indefinitely. "<span className="font-bold">Sensitive Personal Information</span>" shall mean passwords and any other information that is classified as sensitive under Section 3 of the Rules.</span>
                            </div>
                            <div className="flex flex-col gap-4">
                                <span className="text-[24px] font-semibold">Third Party Links</span>
                                <span className="text-[14px]">The Platform may, from time to time, contain links to and from the websites of our partner networks, advertisers, Affiliates and/or any other websites or mobile applications. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites or mobile applications.</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col text-left gap-8">
                        <span className="text-[34px] font-semibold">CHANGES TO THIS POLICY</span>
                        <span className="text-[14px]">The Company may periodically update this Privacy Policy. Whenever we make any changes to this Privacy Policy that are important for you to know about, we will post the updated Privacy Policy. It is your responsibility to keep checking this page from time to time in order to be aware of any changes made to this Privacy Policy.</span>
                    </div>
                    <div className="flex flex-col text-left gap-8">
                        <span className="text-[34px] font-semibold">DISCLAIMER</span>
                        <span className="text-[14px]">Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Information, we cannot guarantee the security of your data transmitted to the Platform; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</span>
                    </div>
                    <div className="flex flex-col text-left gap-10">
                        <span className="text-[34px] font-semibold">COMMUNICATIONS FROM US</span>
                        <span className="text-[14px]">We may from time to time send you service-related announcements when we consider it necessary to do so (such as when we temporarily suspend the Platform for maintenance, or security, privacy, or administrative-related communications). We send these to you via SMS. You may not opt-out of these service-related announcements, which are NOT promotional in nature and used solely to protect your account and keep you informed of important changes to the Platform.</span>
                        <div className="flex flex-col gap-4">
                            <span className="text-[24px] font-semibold">Grievance Redressal</span>
                            <span className="text-[14px]">Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the Company in writing at this email address: <span className="font-bold">legal@sauda.io</span> or contact us here: SaudaPro Private Limited, #703, 22nd A Main Rd, 2nd Sector, HSR Layout, Bengaluru, Karnataka 560102</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;