import { useNavigate } from "react-router-dom";

const Header = () => {
    const Navigate = useNavigate();
    const HomeClick = () => {
        Navigate('/Home');
    }

    return (
        <>
            <div className="bg-white flex justify-between py-5 text-black px-20 border-b-[1px] border-gray-200 fixed w-full">
                <div className="flex gap-5">
                    <div className="pr-10 ml-10 text-[30px] font-medium cursor-pointer" onClick={() => HomeClick()}>
                    <img src="/images/LogoSub.png" alt="" className="h-[50px]" />
                    </div>
                </div>
                <div className="flex justify-center items-center border border-[#157bfb] rounded-xl">
                    <div className="px-5 font-medium text-[#157bfb]">Download App</div>
                </div>
            </div>
        </>
    )
}

export default Header;
