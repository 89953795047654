import React, { useState } from 'react';

const Pro_Con = ({ pros, cons }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full mx-auto text-left">
            <div className="bg-[#f8fbff] rounded py-5 min-[800px]:py-10 border-dashed border-b-[1px] border-gray-200">
                <div className='w-full flex flex-row justify-between items-center'
                    onClick={toggleOpen}>
                    <h1
                        className="font-semibold text-[14px] min-[800px]:text-[22px] mb-2 cursor-pointer"
                    >
                        Advantages and Disadvantages:
                    </h1>
                    {
                        !isOpen ? <img className='w-5 h-5' src='images/down.png' />
                            : <img className='w-5 h-5' src='images/up.png' />
                    }
                </div>
                {isOpen && (
                    <>
                        <div className="flex flex-col text-left gap-3 text-[12px] mt-3">
                            <div>
                                <span className="font-bold">Pros: </span><span>{pros}</span>
                            </div>
                            <div className="text-left text-[12px]">
                                <span className="font-bold">Cons: </span><span className="text-left">{cons}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Pro_Con;