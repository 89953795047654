const Terms = () => {
    return (
        <div className="flex w-full justify-center items-center">
            <div className="flex flex-col gap-8 px-5 mt-32 min-[800px]:w-1/2">
                <div className="flex flex-col text-left gap-8">
                    <span className="text-[34px] font-bold">TERMS OF USE</span>
                    <div className="flex flex-col gap-4">
                        <span className="text-[14px] italic">[Last Revised: Sept 25, 2023]</span>
                        <span className="text-[14px]">These Terms of Use ("<span className="font-bold">Terms</span>") govern your access and use of our website located at <span className="font-bold text-blue-500">https://sauda.io</span> and/or the Sauda Pro mobile application (together referred to as the "<span className="font-bold">Platform</span>") and any services offered through the Platform ("<span className="font-bold">Services</span>"). The Services are provided by SaudaPro Private Limited ("<span className="font-bold">Sauda Pro</span>," "<span className="font-bold">Company</span>," "<span className="font-bold">we</span>," "<span className="font-bold">us</span>," or "<span className="font-bold">our</span>"), a private company established under the laws of India, with its registered office at #703, 22nd A Main Rd, 2nd Sector, HSR Layout, Bengaluru, Karnataka 560102. Any references to "<span className="font-bold">you</span>", "<span className="font-bold">your</span>" or "<span className="font-bold">user</span>" shall mean any person or entity using our Platform.</span>
                        <span className="text-[14px]">These Terms are to be read with the Sauda Pro Privacy Policy and Sauda Pro Content Policy. If you do not agree with the terms and conditions of these Terms, please do not use this Platform.</span>
                        <span className="text-[14px]">Our Services (as we have described below in detail) and these Terms are compliant with the Indian Penal Code, 1860, and Information Technology Act, 2000, including all amendments made to it and rules framed under it. When you create an account on our Platform or use our Platform or any of our Services, you accept and agree to these Terms. However, please note that we do not represent that we are compliant with laws of any country apart from India. If you wish to use our Services, please ensure that you are permitted to do so, in your jurisdiction.</span>
                        <span className="text-[14px] italic">You agree to abide by these Terms as well as our Privacy Policy, Content Policy, and any other policies we may enact, which are hereby incorporated by reference. By using our Platform, you also agree to comply with all applicable local, state, national, and international laws, statutes, ordinances, and regulations, and to be solely responsible for your actions while using our Platform.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">CHANGES TO TERMS AND SERVICES</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">Our Platform is dynamic and may change rapidly. As such, we may change the services we provide at our discretion. We may temporarily, or permanently, stop providing Services or any features to you generally.</span>
                                <span className="text-[14px]">We may remove or add functionalities to our Platform and Services without any notice. However, if we make a change where your consent is required, we will make sure to ask for it. Please be sure to keep visiting this page from time to time to stay updated on our latest changes and developments.</span>
                                <span className="text-[14px]">Visit this page to see any changes that we may make and services that we may add or modify, from time to time.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">WHO MAY USE OUR SERVICES</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">Sauda Pro is a platform for discovering and disseminating information and insights about stocks and markets. We welcome a spectrum of opinions and robust discussions. We understand your preferred content and personalize your newsfeed to show you posts and suggest content available on our Platform.</span>
                                <span className="text-[14px]">You may use our Services only if you are capable of forming a binding agreement with us and are legally permitted to use our Services. If you are accepting these Terms on behalf of a company or any legal persons, then you represent and warrant that you have the authority to bind such entity to these Terms and effectively "you" and "your" shall refer to the company. Please ensure that you are allowed to use our services under the law.</span>
                                <span className="text-[20px] font-semibold">Eligibility</span>
                                <span className="text-[14px]">To access or use our Platform, you must be at least 13 years of age or older. If you are under 13, you are not permitted to use the Platform and should not attempt to sign up for an account or submit any personal information to us. By using the Platform, you represent and warrant that you meet the eligibility requirements. In some jurisdictions, the age of digital consent may be older, and in such cases, users under that age are not permitted to use the Platform.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">HOW TO USE OUR SERVICES</span>
                        <span className="text-[14px]">To provide you with our unique and personalized Services, we need to access specific features of your mobile device. By using Sauda Pro, you grant us permission to access your phone book, SMS inbox, mobile gallery, and mobile device camera. We assure you that we do not read any information stored on your mobile device and computer without your express permission.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">PRIVACY POLICY</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">To effectively provide and introduce any new Services to you, we collect certain information such as your phone number, your name, username from you. We may further request and store additional information. Such information is stored securely on amazon web services or "AWS" cloud servers and the "Google Cloud Platform" cloud servers, thereby also subject to the terms of the AWS and Google Cloud privacy policy. The Sauda Pro Privacy Policy explains how we collect, use, share and store the information collected. The Sauda Pro Privacy Policy also details your rights under law and how you may control the data you provide us.</span>
                                <span className="text-[14px]">We have described how we store and use this information in the Sauda Pro Privacy Policy.</span>
                                <span className="text-[14px]">As stated under the Privacy Policy, we may also use third-party embeds and services on the Platform. The usage of such API services and embeds are covered by the policies of such third-party services. By using such embed or API services, you agree to be bound by the third party's terms of service as provided here.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">SITE CONTENT</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">You understand that all postings, messages, text, images, video or other materials ("Content") posted or published on, uploaded to, transmitted through, or linked from, the Service (hereinafter, “post”, “posted” or “published”), whether by Sauda Pro, users of the Service (“Users”), are the sole responsibility of the person or entity from whom such Content originated.</span>
                                <span className="text-[14px]">You agree that by using the Service, you may be exposed to Content that is offensive, indecent, inaccurate, misleading, or otherwise objectionable. Sauda Pro, its affiliates and partners are not responsible for the conduct, whether online or offline, of any User. You agree that you bear all risks associated with, the use or posting of any Content, that you may not rely on said Content, and that under no circumstances will Sauda Pro, its affiliates, or partners be liable in any way for any Content or for any loss or damage of any kind incurred as a result of the use of any Content posted or otherwise made available via the Service.</span>
                                <span className="text-[14px]">Content available on the Sauda Pro platform contain the Users’ own opinions (and not those of the Company), and none of the information contained therein constitutes a recommendation that any particular security, portfolio of securities, transaction, or investment strategy is suitable for any specific person. You further understand that the Users will not advise you personally concerning the nature, potential, value or suitability of any particular security, portfolio of securities, transaction, investment strategy or other matter.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">USER CONTENT POSTED ON THE SERVICE</span>
                        <div className="flex flex-col w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">You are solely responsible for the Content that you post on or through the Service, or otherwise transmit to or share with other Users (collectively, the "User Content"). You shall not (and shall not permit any third party to) create, upload, download, post, submit or otherwise distribute or facilitate distribution of any User Content on or through the Service, that:</span>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>you did not create or have permission to post;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>infringes any patent, trademark, trade secret, copyright, moral right, right of publicity, right of privacy, or other right of any other person or entity or violates these Terms or any law or contractual duty;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>you know is false, misleading, untruthful or inaccurate;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>defames, libels, ridicules, mocks, disparages, threatens, harass, intimidates or abuses anyone;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>attempts to impersonate any other party;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>harvests or otherwise collects information about users without their consent;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>uses tools which anonymize your internet protocol address (e.g. anonymous proxy) to access the Service;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>constitutes unauthorized or unsolicited advertising, junk or bulk email or pyramid schemes; or</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>includes anyone’s identification documents or sensitive financial information.</span>
                                </div>
                                <span className="text-[14px]">You acknowledge that Sauda Pro does not pre-screen, endorse or approve User Content, but that Sauda Pro shall have the right (but not the obligation) in its sole discretion to refuse, delete or remove any User Content that is available via the Service, including in connection with violations of the letter or spirit of the Terms or for any other reason. You are solely responsible at your sole cost and expense for creating backup copies and replacing any User Content you post or store on the Service or otherwise provide to the Company.</span>
                                <span className="text-[14px]">When you post User Content to the Service, you authorize and direct us to make such copies thereof as we deem necessary in order to facilitate the posting and storage of the User Content on the Service. By posting User Content to any part of the Service, you automatically grant, and you represent and warrant that you have the right to grant, to Sauda Pro an irrevocable, perpetual, non-exclusive, transferable, fully paid, worldwide license (with the right to sublicense) to use, copy, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part) and distribute such User Content, and your name, voice, and/or likeness as contained in your Content, for any purpose, commercial, advertising, or otherwise, on or in connection with the Service or the promotion thereof, to prepare derivative works of, or incorporate into other works, such User Content, and to grant and authorize of the foregoing. Sauda Pro does not assert any ownership over your User Content; rather, as between us and you, subject to the rights granted to us in these Terms, you retain full ownership of all of your User Content and any intellectual property rights or other proprietary rights associated with your User Content. You also hereby grant each user of the Service a non-exclusive license to access your User Content through the Service, and to use, reproduce, distribute, display and perform such User Content as permitted through the functionality of the Service and under these Terms.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">SECURITIES DISCLAIMER</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">In simple terms, Sauda Pro is not responsible for any losses you may incur and does not provide specific advice for individual users.</span>
                                <span className="text-[14px] font-semibold">Sauda Pro IS NOT A TAX ADVISOR, BROKER, FINANCIAL ADVISOR OR INVESTMENT ADVISOR. THE SERVICE IS NOT INTENDED TO PROVIDE TAX, LEGAL, FINANCIAL OR INVESTMENT ADVICE, AND NOTHING ON THE SERVICE SHOULD BE CONSTRUED AS AN OFFER TO SELL, A SOLICITATION OF AN OFFER TO BUY, OR A RECOMMENDATION FOR ANY SECURITY.</span>
                                <span className="text-[14px]">Trading in such securities can result in immediate and substantial losses of the capital invested. You should only invest risk capital, and not capital required for other purposes. You alone are solely responsible for determining whether any investment, security or strategy, or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation. You should also consult an attorney or tax professional regarding your specific legal or tax situation.</span>
                                <span className="text-[14px]">The Content is to be used for informational and entertainment purposes only and the Service does not provide investment advice for any individual. Sauda Pro, its affiliates and partners specifically disclaim any and all liability or loss arising out of any action taken in reliance on Content, including but not limited to market value or other loss on the sale or purchase of any company, property, product, service, security, instrument, or any other matter.</span>
                                <span className="text-[14px]">You understand that an investment in any security is subject to a number of risks, and that discussions of any security published on the Service will not contain a list or description of relevant risk factors. In addition, please note that some of the stocks about which Content is published on the Service have a low market capitalization and/or insufficient public float. Such stocks are subject to more risk than stocks of larger companies, including greater volatility, lower liquidity and less publicly available information.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">RESTRICTIONS ON CONTENT AND USE OF SERVICE</span>
                        <div className="flex flex-col w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">In addition, you agree not to use the Service to:</span>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Service;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with any person or entity;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>post or otherwise make publicly available on the Service any private information of any third party, including addresses, phone numbers, email addresses, Aadhar numbers and credit card numbers;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>post or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>post or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>violate any applicable law or regulation while accessing and using the Service, including, without limitation, the rules and regulations of relevant financial and securities regulatory bodies including, but not limited to, the Securities and Exchange Board of India (especially and including the rule against making false or misleading statements to manipulate the price of any security);</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>interfere with the working of our Services, or impose an unreasonable or disproportionately large load on our infrastructure;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted by you; or</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>post or otherwise make available any information that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements).</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">COPYRIGHT INFRINGEMENT</span>
                        <div className="flex flex-col w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">We respect the intellectual property rights of others and we prohibit Users from posting or otherwise transmitting on the Service any materials that violate another party's intellectual property rights. If you believe that any content on Sauda Pro infringes your copyrights, please send written notice to: legal@sauda.io</span>
                                <span className="text-[14px]">This notice should include the following information:</span>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>The electronic or physical signature of the copyright owner, or a person authorized to act on their behalf.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>A description of the copyrighted work that you claim has been infringed.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>A description of the exact location on Sauda Pro of the content that you claim is infringing. This description must allow us to find and identify the content.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>Your name, address, telephone number and email address.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>A statement by you that: a) you believe in good faith that the use of the content that you claim to infringe your copyright is not authorized by law, the copyright owner, or the owner’s agent, b) all information contained in your copyright notice is accurate, and c) under penalty of perjury, you are either the copyright owner, or authorized to act on their behalf.</span>
                                </div>
                                <span className="text-[14px]">In appropriate circumstances we may also terminate the accounts of repeat infringers.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">PERMISSIONS YOU GIVE TO US</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">You accept these Terms and give us certain permissions so that we can serve you better. Permissions you grant to us are:</span>
                                <span className="text-[20px] font-semibold">Permission to Share Your Profile Information with Third Parties</span>
                                <span className="text-[14px]">While our Platform is a freely accessible and usable platform, we need to generate revenue so that we can keep offering you our Services for free. Pursuant to this, we may share any data that we may collect including but not limited to your username, profile picture, your usage and engagement habits and patterns on our Platform to show you any sponsored content or advertisements. We will not, however, be liable to pay you any revenue share if you purchase any products advertised to you. We do not endorse any products or vouch for the authenticity of the products. Merely advertising of products by the users on our Platform does not amount to an endorsement by us.</span>
                                <span className="text-[14px]">If we share any sensitive personal information (as defined under the applicable laws), we will ask you for your consent prior to sharing the same.</span>
                                <span className="text-[20px] font-semibold">Automatic Downloads and Updates</span>
                                <span className="text-[14px]">To utilize our Platform to its full capacity, you will need to download and regularly update the Sauda Pro mobile application on your mobile device.</span>
                                <span className="text-[20px] font-semibold">Permission to Use Cookies</span>
                                <span className="text-[14px]">We may use cookies, pixel tags, web beacons, mobile device IDs, flash cookies and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites. Many websites use cookies and store them on your web browser so that usage information can be stored and logged in your browser. For more details, please read the Sauda Pro Privacy Policy.</span>
                                <span className="text-[20px] font-semibold">Data Retention</span>
                                <span className="text-[14px]">We shall have the right to retain certain information regarding your usage of the Platform. Please view the Sauda Pro Privacy Policy for further information relating to the collection, processing, storage and use of your information by us.</span>
                                <span className="text-[14px]">You grant us the right to process, store and retain information relating to you and provided by you. Please see the Privacy Policy for further information.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">OUR AGREEMENT AND WHAT HAPPENS IF WE DISAGREE</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[20px] font-semibold">Who Has Rights Under These Terms</span>
                                <span className="text-[14px]">The rights and obligations under these Terms are granted only to you and shall not be assigned to any third party without our consent. However, we are permitted to assign our rights and obligations under these Terms to others. This can happen when, for example, we enter into a merger with another company and create a new company.</span>
                                <span className="text-[20px] font-semibold">How Will We Handle Disputes</span>
                                <span className="text-[14px]">In all cases, you agree that disputes will be subject to the laws of India and the courts of Bangalore shall have exclusive jurisdiction over all such disputes.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">LIMITATION OF LIABILITY</span>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">We do not assume any liability with respect to any loss or damage, arising directly or indirectly due to any inaccuracy or incompleteness of any information or a breach of any warranty or guaranty due to the actions of any user of the Platform.</span>
                                <span className="text-[14px]">The Platform and Services, are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. We do not warrant the quality of the Services or the Platform including its uninterrupted, timely, secure or error-free provision, continued compatibility on any device, or correction of any errors.</span>
                                <span className="text-[14px]">In no event shall we, or any of our affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, service providers, and suppliers be liable for any special, incidental, punitive, direct, indirect or consequential damages suffered as a consequence of a breach of the Terms by another user or arising out of the use of or the reliance on any of the Services or the Platform.</span>
                                <span className="text-[14px]">In the event any exclusion contained herein is held to be invalid for any reason and we or any of our affiliate entities, officers, directors or employees become liable for loss or damage, then, any such liability shall be limited to not exceeding the charges or amounts paid to us for use of the Platform or the Services in the month preceding the date of the claim.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">INDEMNIFICATION</span>
                        <div className="flex flex-col w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <span className="text-[14px]">You agree to indemnify, defend and hold harmless us, and our subsidiaries, affiliates and agents and their respective officers, directors, employees, successors and assigns from and against any claim, proceeding, loss, damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind arising out of:</span>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>your access to or use of the Platform and Services;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>any breach by you of your obligations under this Agreement;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>your violation of the rights of any third party, including any infringement of intellectual property, or of any privacy or consumer protection right;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>any violation of law or contractual obligation and any claims, demands, notices pursuant to such violation;</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>your negligence or willful misconduct.</span>
                                </div>
                                <span className="text-[14px]">This obligation will survive the termination of our Terms.</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">UNSOLICITED MATERIAL</span>
                        <span className="text-[14px]">We always appreciate feedback or other suggestions. We may use the same without any restrictions or obligation to compensate you for them and are under no obligation to keep them confidential.</span>
                    </div>
                    <div className="flex flex-col gap-4 pb-10">
                        <span className="text-[24px] font-bold">GENERAL</span>
                        <div className="flex flex-col w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>If we fail to act on any breaches of these Terms, it does not waive our right to act on subsequent or similar breaches.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>Any amendment or waiver to our Terms must be in writing and signed by us.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>If we fail to enforce any aspect of these Terms, including reporting any illegal or impermissible actions to appropriate law enforcement authorities or blocking or suspending your profile, such failure to enforce our rights will not be a waiver by us.</span>
                                </div>
                                <div className="flex flex-row gap-2 pl-8">
                                    <span>●</span>
                                    <span>We reserve all rights not expressly granted to you.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;