const About = () => {
    return (
        <div className="flex w-full justify-center items-center">
            <div className="flex flex-col gap-8 px-5 min-[800px]:w-1/2 mt-32">
                <div className="flex flex-col text-left gap-10">
                    <span className="text-[34px] font-semibold">ABOUT US</span>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-semibold">WHO ARE WE?</span>
                        <span className="text-[14px]">Sauda Pro is a product by SaudaPro Pvt Ltd. The company is run by a team of media loving techies. In our past lives, we were employed in various technology and product roles at some of India's most well known Internet companies.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-semibold">CONTACT US</span>
                        <div className="flex flex-col">
                            <span className="text-[14px]">SaudaPro Private Limited</span>
                            <span className="text-[14px]">#703, 22nd A Main Rd,</span>
                            <span className="text-[14px]">2nd Sector, Saki Naka,</span>
                            <span className="text-[14px]">Kajupada, Mumbai 400001</span>
                        </div>
                    </div>
                </div>
                <span className="text-[14px] text-left">Please email us at help@saudapro or send us a message at <span className="text-blue-500">@Sauda Prosupport</span> for any queries, concerns or suggestions.</span>
            </div>
        </div>
    )
}

export default About;