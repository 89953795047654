// import logo from './logo.svg';
import Layout from './components/layout';
import './App.css';

//import pages
import Home from './pages/Home';
import About from './pages/AboutUs';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Content from './pages/Content';

const routes = [
  { path: '*', component: Home },
  { path: '/', component: Home },
  { path: '/Home', component: Home },
  { path: '/About', component: About },
  { path: '/Privacy', component: Privacy },
  { path: '/Content', component: Content },
  { path: '/Terms', component: Terms },
]

function App() {
  return (
    <div className="App h-[100vh]">
      <Layout routes={routes} />
    </div>
  );
}

export default App;
