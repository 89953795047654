const Content = () => {
    return (
        <div className="flex w-full justify-center items-center">
            <div className="flex flex-col gap-8 px-5 mt-32 min-[800px]:w-1/2">
                <div className="flex flex-col text-left gap-8">
                    <span className="text-[34px] font-bold">CONTENT POLICY</span>
                    <div className="flex flex-col gap-4">
                        <span className="text-[14px] italic">[Last Revised: Sept 25, 2023]</span>
                        <span className="text-[14px]">These Content and Community Guidelines ("<span className="font-bold">Guidelines</span>") govern your use of our website located at <span className="text-blue-500">https://sauda.io</span> and/or the Sauda Pro mobile applications (collectively, the "<span className="font-bold">Platform</span>") made available by SaudaPro Private Limited ("<span className="font-bold">Sauda Pro</span>", "<span className="font-bold">Company</span>", "<span className="font-bold">we</span>", "<span className="font-bold">us</span>" and "<span className="font-bold">our</span>"), a private company established under the laws of India having its registered office at #703, 22nd A Main Rd, 2nd Sector, HSR Layout, Bengaluru, Karnataka 560102. The terms "<span className="font-bold">you</span>" and "<span className="font-bold">your</span>" refer to the users of the Platform.</span>
                        <span className="text-[14px]">These Guidelines are to be read with the Sauda Pro Terms of Use and Sauda Pro Privacy Policy. Please note that we may change these Guidelines from time to time and we reserve the right to do so.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">CONTENT GUIDELINES</span>
                        <span className="text-[14px]">We actively remove content which is not allowed on our Platform and violates both our Guidelines as well as applicable Indian laws. If such content comes to our attention, we may take it down or ban user accounts. If you come across any content that violates these Guidelines, we encourage you to report it. We understand the importance of creative freedom, however we do not welcome content that intends to bring discomfort, spread what may be considered hate speech and abuse or promote violence and illegal activities.</span>
                        <span className="text-[20px] font-semibold">Adherence to Applicable Laws</span>
                        <span className="text-[14px]">All content, including without limitation, content that is uploaded, posted, commented on, or shared by you on our Platform, must adhere to the laws of India, including without limitation, the Indian Penal Code, 1860 and, the Information Technology Act, 2000 along with all rules and amendments made under such laws. We cooperate with legal authorities and follow enforcement mechanisms in cases of breach of applicable laws.</span>
                        <span className="text-[20px] font-semibold">Stock or Financial Information Manipulation</span>
                        <span className="text-[14px]">Users who violate these guidelines might face legal consequences beyond the actions the Platform will take.</span>
                        <div className="flex flex-col pl-8">
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No False Information:</span>
                                    <span> Users must not knowingly post false, misleading, or inaccurate stock or financial information.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No Rumors or Unverified Claims: </span>
                                    <span> Spreading unverified or unsubstantiated rumors about companies or stocks is strictly prohibited.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Transparent Motivations: </span>
                                    <span>If users have affiliations or interests in a particular stock or financial instrument they are discussing, they must disclose this information.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No Manipulative Activities: </span>
                                    <span> Users are prohibited from engaging in any activities intended to manipulate stock prices or financial markets, including posting misleading information or engaging in "pump and dump" schemes.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No Insider Trading Information: </span>
                                    <span> Posting non-public, material information about companies, or promoting discussions based on insider trading information is strictly prohibited.</span>
                                </div>
                            </div>
                        </div>
                        <span className="text-[20px] font-semibold">Spam and Promotions</span>
                        <span className="text-[14px]">Platform is dedicated to maintaining a clutter-free and genuine discussion environment. To this end, the following guidelines should be adhered to by all users:</span>
                        <div className="flex flex-col pl-8">
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No Spamming:</span>
                                    <span> Users must not post irrelevant, unwarranted, or repetitive content or comments.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">No Promotional Content: </span>
                                    <span> Unsolicited promotional content, advertisements, or solicitations are strictly prohibited unless explicitly permitted by the Platform. Promotion of financial products or services without proper disclosures and permissions is not allowed.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Referral Links & Affiliate Marketing: </span>
                                    <span>If users have affiliations or interests in a particular stock or financial instrument they are discussing, they must disclose this information.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Solicitation and Financial Advice: </span>
                                    <span> Soliciting business, investments, or other services, particularly unsolicited, is prohibited.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">User Accounts: </span>
                                    <span> Creating multiple accounts or using automated bots to post content is strictly prohibited. Such accounts will be banned, and related content will be removed.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Enforcement: </span>
                                    <span> Violations of this policy will result in content removal, warnings, and potentially permanent bans, depending on the severity and frequency of the violations. Platform reserves the right to determine what constitutes spam and promotional content and to take appropriate actions.</span>
                                </div>
                            </div>
                        </div>
                        <span className="text-[20px] font-semibold">Prohibited Content</span>
                        <div className="flex flex-col pl-8">
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Hateful Conduct:</span>
                                    <span> Any content promoting hate, discrimination, or violence against individuals or groups based on race, ethnicity, nationality, religion, gender, gender identity, sexual orientation, disability, or medical condition is prohibited.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Illegal Activities: </span>
                                    <span> Content promoting or discussing illegal activities is not allowed.</span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <div>
                                    <span className="font-semibold">Personal and Confidential Information: </span>
                                    <span>Posting someone else’s personal and confidential information without their explicit consent is prohibited.</span>
                                </div>
                            </div>
                        </div>
                        <span className="text-[14px]">The Platform will cooperate with legal authorities if any illegal activity is detected.</span>
                    </div>
                    <div className="flex flex-col gap-4">
                        <span className="text-[24px] font-bold">MODERATION AND ENFORCEMENT GUIDELINES</span>
                        <span className="text-[14px]">Violations of Guidelines will result in appropriate actions, including content removal and account suspension. Users are encouraged to report any suspicious activities or content that violates this policy.</span>
                        <span className="text-[20px] font-semibold">User Reporting</span>
                        <span className="text-[14px]">When you see any content or activity that violates these Guidelines, please tap or click on the REPORT button. The moment you tap or click on this tab, we will get a notification and will process your request. If we find the content or activity to be unsuitable for our Platform, human content reviewers or moderators will remove it.</span>
                        <span className="text-[20px] font-semibold">Automated Moderation</span>
                        <span className="text-[14px]">Automated content moderation is a crucial component of our efforts to maintain a safe and respectful environment on the Platform. It involves using algorithms, machine learning, and other automated systems to scan and analyze user-generated content, detecting potential violations of our Guidelines promptly.</span>
                        <div className="flex flex-col pl-8">
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <span>Our automated systems analyze content based on predefined criteria and parameters reflecting our content policies.</span>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <span>Content flagged by the automated systems undergoes further review to confirm whether it violates our policies.</span>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span>●</span>
                                <span>Actions such as content removal, user warnings, or account suspensions may be implemented, depending on the severity of the violation.</span>
                            </div>
                        </div>
                        <span className="text-[20px] font-semibold">Intermediary Status and Review of Content</span>
                        <span className="text-[14px]">We are an intermediary as per the applicable laws. We do not control what our users post, comment, share or say on the Platform and are not responsible for their (or your) actions (whether online or offline). Our responsibility and liability for anything that happens on our Platform is strictly governed and limited by the laws of India.</span>
                        <span className="text-[14px]">We expect you to be responsible for what you post and what you see. If any of our users report your content to be against these Guidelines, we may take enforcement actions as necessary. The final decision lies with Sauda Pro in case of any disputes over content removal.</span>
                        <span className="text-[20px] font-semibold">Right to Challenge</span>
                        <span className="text-[14px]">If your content or activity is reported by another user and subsequently removed from our Platform, you will be notified of such removal and provided with our reasons for the action. If you disagree with our decision, you may challenge the removal by writing to us at <span className="font-bold text-blue-500">legal@sauda.io</span> within 7 days of receiving our notification. Please include specific details and reasons as to why you believe the content removal was unfair, along with any supporting documentation, if available. We will reassess the content in light of your challenge and determine whether it is in compliance with our Guidelines.</span>
                        <span className="text-[14px]">After reviewing your challenge, we will communicate our final decision to you. Please be advised that our decision on whether the content may be reposted or remains removed will be conclusive and binding. Your right to challenge does not extend to multiple or repetitive submissions on the same content removal, and we reserve the right to limit or deny your challenge rights if we determine that your challenges are frivolous or are intended to harass.</span>
                        <span className="text-[14px]">We encourage all users to be mindful of our Guidelines and to foster a respectful and inclusive environment on our Platform. Your cooperation in adhering to these Guidelines ensures a positive and enriching experience for all users on Sauda Pro.</span>
                        <span className="text-[20px] font-semibold">Actions Against Violations</span>
                        <span className="text-[14px]">We enforce a strict policy against those who breach these Guidelines. If your profile is found to be in violation, an immediate suspension may be imposed. In cases of recurrent violations, we reserve the right to permanently ban your account, preventing any future registrations with us.</span>
                        <span className="text-[14px]">We intend to maintain a respectful and safe environment, and as such, we will cooperate fully with any lawful requests from law enforcement agencies. However, it is imperative to understand that we are under no obligation to assist you in legal matters related to content violations.</span>
                        <span className="text-[14px]">We aim to protect the integrity of our platform and the experience of our users. Thus, we request your adherence to our Guidelines to maintain a harmonious community environment on Sauda Pro.</span>
                    </div>
                    <span className="text-[24px] font-bold">GRIEVANCE REDRESSAL</span>
                    <div className="flex flex-col gap-4 pb-10">
                        <span className="text-[14px]">Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the Company in writing at this email address: <span className="font-bold">legal@sauda.io</span></span>
                        <span className="text-[14px]">You can also contact us here: SaudaPro Private Limited, #703, 22nd A Main Rd, 2nd Sector, Saki Naka, Kajupada, Mumabi 400001</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;