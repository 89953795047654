import React, { useState } from 'react';

const Difference = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full mx-auto text-left">
            <div className="bg-[#f8fbff] rounded py-5 min-[800px]:py-10 border-dashed border-b-[1px] border-gray-200">
                <div className='w-full flex flex-row justify-between items-center'
                    onClick={toggleOpen}>
                    <h1
                        className="font-semibold text-[14px] min-[800px]:text-[22px] mb-2 cursor-pointer"
                    >
                        {question}
                    </h1>
                    {
                        !isOpen ? <img className='w-5 h-5' src='images/down.png' />
                            : <img className='w-5 h-5' src='images/up.png' />
                    }
                </div>
                {isOpen && (
                    <p className="mt-5 text-gray-700 text-[12px] min-[800px]:text-[17px] w-4/5">
                        {answer}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Difference;