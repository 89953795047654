import "./style.scss";
import Question from "./components/question";
import { useState } from "react";
import Learn from "./components/learn";
import Difference from "./components/difference";
import Pro_Con from "./components/pro_con";
// import TestimonialSlider from "./components/testimonialslider";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isShow, setIsShow] = useState(false);
  const toggleShow = () => {
    setIsShow(!isShow);
  };

  const [isQues, setIsQues] = useState(false);
  const toggleQues = () => {
    setIsQues(!isQues);
  };

  const [isPaperTrade, setIsPaperTrade] = useState(true);
  const togglePaperTrade = () => {
    setIsPaperTrade(!isPaperTrade);
  };

  const [isDifference, setisDifference] = useState(false);
  const toggleDifference = () => {
    setisDifference(!isDifference);
  };

  const [isTips, setIsTips] = useState(false);
  const toggleTips = () => {
    setIsTips(!isTips);
  };

  const Navigate = useNavigate();

  const openClient = () => {
    let url = "https://client.sauda.io";
    window.open(url, "__blank");
  };

  const openPlayStore = () => {
    let url =
      "https://play.google.com/store/apps/details?id=com.saudapro&hl=en_IN&gl=US";
    window.open(url, "__blank");
  };

  const openIos = () => {
    let url =
      "https://apps.apple.com/in/app/zerodha-kite-trade-invest/id1449453802";
    window.open(url, "__blank");
  };

  const openYoutube = () => {
    let url =
      "https://www.youtube.com/@SAUDATHEPAPERTRADING";
    window.open(url, "__blank");
  };

  const openInstagram = () => {
    let url =
      "https://www.youtube.com/@SAUDATHEPAPERTRADING";
    window.open(url, "__blank");
  };
  
  const openFacebook = () => {
    let url =
      "https://www.youtube.com/@SAUDATHEPAPERTRADING";
    window.open(url, "__blank");
  };

  const openX = () => {
    let url =
      "https://www.youtube.com/@SAUDATHEPAPERTRADING";
    window.open(url, "__blank");
  };

  return (
    <div className="w-full mt-20">
      <div className="flex flex-col px-5 min-[800px]:px-20">
        <div className="min-[800px]:block min-w-[400px] w-1/3 justify-between bg-[#f0f7ff] rounded-md mt-20 hidden">
          <div className="flex flex-row px-2 py-4 w-full">
            <div className="flex flex-row w-full justify-center items-center gap-2">
              <img className="w-5 h-5" src="images/rocket.svg" />
              <span className="font-medium">1M+ Downloads</span>
            </div>
            <div className="flex flex-row w-full justify-center items-center gap-2">
              <img className="w-5 h-5" src="images/star.svg" />
              <span className="font-medium">4.8 Playstore rating</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col min-[800px]:flex-row">
          <div className="flex flex-col mt-20 w-full">
            <span className="text-left text-[30px] min-[800px]:text-[56px] font-semibold w-full min-[800px]:w-3/4">
              Learn Financial Markets via Paper Trading
            </span>
            <span className="mt-10 text-left leading-6 text-[14px] min-[800px]:text-[22px] w-full min-[800px]:w-3/4">
              SEBI reports that 90% of traders face losses and exit the market.
              Enhance your longevity by testing strategies with our
              user-friendly virtual trading app.
            </span>
            <div
              onClick={openClient}
              className="mt-10 w-1/4 px-3 py-3 bg-blue-500 text-white rounded-md font-semibold hidden min-[800px]:block"
            >
              Get started for FREE
            </div>
          </div>
          <div className="min-[800px]:w-1/2 m-auto w-1/2 h-auto">
            <img src="images/first.png" />
          </div>
          <div className="mt-10 w-full px-3 py-3 bg-blue-500 text-white rounded-md font-semibold block min-[800px]:hidden">
            Download Sauda Pro
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-2 min-[800px]:hidden">
          <div className="flex flex-row px-2 py-4 gap-4">
            <div className="flex flex-row justify-center items-center gap-2">
              <img className="w-5 h-5" src="images/rocket.svg" />
              <span className="font-medium">1M+ Downloads</span>
            </div>
            <div className="flex flex-row justify-center items-center gap-2">
              <img className="w-5 h-5" src="images/star.svg" />
              <span className="font-medium">4.8 Playstore rating</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 min-[800px]:flex-row w-full">
          <div className="w-full">
            <img className="w-auto" src="images/second.webp" />
          </div>
          <div className="w-full hidden min-[800px]:flex flex-col justify-between">
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Easy-to-Use interface
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Simple and easy-to-use interface that doesn't get in the way of
                your learning. Navigate the markets effortlessly, focusing on
                what matters most - your growth.
              </span>
            </div>
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Frequently Updated & Accurate Data
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Stay ahead with frequently updated market data, offering you an
                edge in your trading strategies.
              </span>
            </div>
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Unlimited Strategy Testing
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Experiment freely with unlimited paper trades, perfecting your
                strategy for the real market scenario.
              </span>
            </div>
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Trade Across Asset Classes
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Diversify with virtual capital of 10 Lac. Trade in Stocks,
                Futures & Options, and Commodities without any financial risks.
              </span>
            </div>
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Stay Informed with Timely Alerts
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Get real-time updates on crucial data points like Watchlist
                reports, market shifts, stock news, SGX Nifty, and FII DII. Stay
                one step ahead in the market with our instant notifications.
              </span>
            </div>
            <div className="flex flex-col justify-center px-10 mt-4">
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  focusable="false"
                  class="md-icon VHy7A noFill mr-15"
                >
                  <g clip-path="url(#clip0_187_2651)">
                    <path
                      d="M8.25 12.7607L10.5 15.0107L15.75 9.76074"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z"
                      stroke="#157BFB"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_187_2651">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.0107422)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[22px] font-semibold">
                  Active Trading Community
                </span>
              </div>
              <span className="text-[#022b6980] text-left py-3 border-b-[1px] border-gray-200 text-[15px]">
                Connect with 10 Lac + traders just like you. Trading can be a
                solitary journey, but it doesn't have to be. Discover new ideas,
                learn together, and support
              </span>
            </div>
          </div>
          <div className="w-full flex flex-col justify-between min-[800px]:hidden">
            <Learn
              question={"Easy-to-Use interface"}
              answer={
                "Simple and easy-to-use interface that doesn't get in the way of your learning. Navigate the markets effortlessly, focusing on what matters most - your growth."
              }
            />
            <Learn
              question={"Frequently Updated & Accurate Data"}
              answer={
                "Stay ahead with frequently updated market data, offering you an edge in your trading strategies."
              }
            />
            <Learn
              question={"Unlimited Strategy Testing"}
              answer={
                "Experiment freely with unlimited paper trades, perfecting your strategy for the real market scenario."
              }
            />
            <Learn
              question={"Trade Across Asset Classes"}
              answer={
                "Diversify with virtual capital of 10 Lac. Trade in Stocks, Futures & Options, and Commodities without any financial risks."
              }
            />
            <Learn
              question={"Stay Informed with Timely Alerts"}
              answer={
                "Get real-time updates on crucial data points like Watchlist reports, market shifts, stock news, SGX Nifty, and FII DII. Stay one step ahead in the market with our instant notifications."
              }
            />
            <Learn
              question={"Active Trading Community"}
              answer={
                "Connect with 10 Lac + traders just like you. Trading can be a solitary journey, but it doesn't have to be. Discover new ideas, learn together, and support each other on this exciting journey."
              }
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mt-20">
            <span className="text-[18px] min-[800px]:text-[40px] text-left font-semibold">
              Frequently Asked Questions (FAQs)
            </span>
            {isQues ? (
              <div
                className="text-3xl min-[800px]:hidden cursor-pointer"
                onClick={toggleQues}
              >
                +
              </div>
            ) : (
              <div
                className="text-4xl min-[800px]:hidden cursor-pointer pr-1"
                onClick={toggleQues}
              >
                -
              </div>
            )}
          </div>
          {!isQues && (
            <>
              <Question
                question="What is Paper Trading?"
                answer="Paper trading is a simulated form of trading that allows beginners to practice on a virtual trading platform without using real money. It's essentially a stock market simulator where you can hone your skills before stepping into the real trading world."
              />
              <Question
                question="How do I get started with paper trading?"
                answer="To start paper trading, sign up on Sauda Pro, a top-rated paper trading platform in India. You'll receive virtual money of 10 Lac to begin your trading journey. Other popular paper trading platforms in India are TradingView, Sensibull, Neostox, Zerodha, and Moneybhai."
              />
              <Question
                question="Is paper trading on Sauda Pro free?"
                answer="Yes, paper trading on Sauda Pro is completely free. It allows you to practice trading without any charges."
              />
              <Question
                question="What all instruments can I trade?"
                answer="You can trade stocks, options, and commodities."
              />
              <Question
                question="Can I paper trade with options?"
                answer="Yes, options trading is fully supported on the Sauda Pro paper trading platform. You can explore the complexities of options pricing, understand volatility, and learn risk management, all within a risk-free environment."
              />
              <Question
                question="Can I skip paper trading and start with real trading?"
                answer="While it's technically possible to skip paper trading and dive directly into real trading, we recommend against it. Real trading can be volatile and complex. Paper trading allows you to familiarize yourself with trading mechanisms and develop strategies without the risk of real financial losses."
              />
              {isShow && (
                <>
                  <Question
                    question="Why is paper trading useful for beginners?"
                    answer="Think of paper trading as a playground for beginners. It's like a sandbox where you can experiment with trading strategies, understand market dynamics, and monitor the impact of news on stocks
                        And the best part? You get to do all of this with virtual money. This way, you get to practice, make mistakes, learn, and gain confidence before you dive into real trading."
                  />
                  <Question
                    question="How long should I paper trade?"
                    answer="There's no hard and fast rule. Some people may need a few weeks, others might take a few months. The key is to practice on your virtual trading platform until you feel confident and start seeing consistent profits in your simulated trades.
                        Think of it as a video game. You don’t start playing at the highest level, right? You level up gradually. Similarly, paper trading helps you level up your trading skills at your own pace. The key is to focus on learning and improving, rather than rushing to the live market"
                  />
                  <Question
                    question="Is there any limit on the number of trades I can do in a day?"
                    answer="There's no limit on the number of trades you can do in a day while paper trading. However, it's advisable to trade sensibly to mimic real market conditions."
                  />
                  <Question
                    question="Can I set stop loss and target on my orders?"
                    answer="Yes, in paper trading you can set both stop loss and target on your orders, just like in real trading. This helps simulate the actual trading environment."
                  />
                  <Question
                    question="Is the data provided by Sauda Pro's paper trading platform reliable ?"
                    answer="Yes, our platform provides accurate data so that you can make informed decisions and perfect your trading strategies."
                  />
                  <Question
                    question="Will it display P&L in runtime when the orders are placed?"
                    answer="Yes, we show your P&L in real time as orders are placed. This helps you to understand the impact of your trades immediately."
                  />
                  <Question
                    question="What are some of the best paper trading apps in India for beginners?"
                    answer="In addition to Sauda Pro, here are some other notable paper trading platforms in India:

                        TradingView: Known for advanced charting features and user-friendly trading simulator, often favoured by global and Indian traders.
                        
                        Neostox: A virtual trading platform with real-time market feeds and the ability to practice trading with virtual money of up to 1 CR across various market segments including equities, FnO, and commodities.
                        
                        MoneyBhai: Allows trading in different asset classes and offers features like portfolio management.
                        
                        Sensibull: Designed for options traders, with virtual money for trading and analysis tools.
                        
                        Zerodha: Offers paper trading through Streak, allowing users to backtest various strategies."
                  />
                </>
              )}
              {!isShow ? (
                <span
                  className="mt-10 text-left font-semibold text-blue-500 underline"
                  onClick={toggleShow}
                >
                  Show more FAQs
                </span>
              ) : (
                <span
                  className="mt-10 text-left font-semibold text-blue-500 underline"
                  onClick={toggleShow}
                >
                  Hide few FAQs
                </span>
              )}
            </>
          )}
        </div>

        {isQues ? <div className="h-[1px] w-full bg-gray-200 mt-8" /> : <div />}

        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mt-10">
            <span className="text-[18px] min-[800px]:text-[40px] text-left font-semibold">
              Why Should You Paper Trade?
            </span>
            {!isPaperTrade ? (
              <div
                className="text-3xl min-[800px]:hidden cursor-pointer"
                onClick={togglePaperTrade}
              >
                +
              </div>
            ) : (
              <div
                className="text-4xl min-[800px]:hidden cursor-pointer pr-1"
                onClick={togglePaperTrade}
              >
                -
              </div>
            )}
          </div>
          {isPaperTrade ? (
            <>
              <div className="grid grid-cols-1 min-[800px]:grid-cols-2 mt-10 bg-[#f8fbff] px-10 py-12 rounded-2xl gap-10">
                <div className="flex flex-col text-left gap-3">
                  <span className="text-[14px] min-[800px]:text-[22px] font-semibold">
                    Test Lab for Trading Strategies
                  </span>
                  <span className="min-[800px]:text-[12px]">
                    This is your risk-free environment to delve into different
                    trading methods. Swing trading or shorting stocks? Our paper
                    trading platform allows you to put these strategies to the
                    test and refine them for real-market application.
                  </span>
                </div>
                <div className="flex flex-col text-left gap-3">
                  <span className="text-[14px] min-[800px]:text-[22px] font-semibold">
                    Risk-free Learning Playground
                  </span>
                  <span className="min-[800px]:text-[12px]">
                    Try your hand at bullish strategies, bearish tactics, or
                    even explore the complexities of options trading. Here's the
                    best part – it's all risk-free, giving you the freedom to
                    learn and experiment.
                  </span>
                </div>
                <div className="flex flex-col text-left gap-3">
                  <span className="text-[14px] min-[800px]:text-[22px] font-semibold">
                    Confidence Booster
                  </span>
                  <span className="min-[800px]:text-[12px]">
                    The sense of accomplishment with every successful virtual
                    trade can do wonders for your self-confidence. Paper trading
                    enables you to build your courage, understanding market
                    trends and patterns, so when you step into real-world
                    trading, you do so with increased assurance.
                  </span>
                </div>
                <div className="flex flex-col text-left gap-3">
                  <span className="text-[14px] min-[800px]:text-[22px] font-semibold">
                    Window into Personal Trading Style
                  </span>
                  <span className="min-[800px]:text-[12px]">
                    Paper trading is more than just practice. It's a magnifying
                    glass that helps identify your trading strengths and
                    weaknesses. It provides you insights into your risk
                    appetite, your patience levels, and how you react to market
                    swings, thus paving the way for crafting your unique trading
                    style.
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div />
          )}
        </div>

        {!isPaperTrade ? (
          <div className="h-[1px] w-full bg-gray-200 mt-8" />
        ) : (
          <div />
        )}

        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mt-10">
            <span className="text-[18px] min-[800px]:text-[40px] text-left font-semibold">
              Paper Trading vs Real Trading: How are they different?
            </span>
            {isDifference ? (
              <div
                className="text-3xl min-[800px]:hidden cursor-pointer"
                onClick={toggleDifference}
              >
                +
              </div>
            ) : (
              <div
                className="text-4xl min-[800px]:hidden cursor-pointer pr-1"
                onClick={toggleDifference}
              >
                -
              </div>
            )}
          </div>
          {!isDifference && (
            <>
              <div className="min-[800px]:flex flex-col hidden">
                <span className="text-[18px] min-[800px]:text-[40px] text-left mt-10 font-semibold tracking-wide"></span>
                <div className="flex flex-col min-[800px]:flex-row gap-6 bg-[#f8fbff] mt-8">
                  <div className="flex flex-col">
                    <div className="flex flex-col bg-[#f8fbff] px-5 py-10 rounded-2xl gap-8 min-[800px]:h-[160vh]">
                      <div className="flex flex-col text-left gap-3">
                        <span className="text-[22px] font-semibold">
                          Paper Trading
                        </span>
                        <span>A Risk-Free Market Simulator</span>
                      </div>
                      <div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            The Purpose of Paper Trading
                          </span>
                          <span>
                            Paper trading serves to mimic real-world trading
                            scenarios without real financial implications. The
                            primary goal of a paper trading account is to
                            provide traders with a safe space to enhance their
                            trading strategies, sharpen their decision-making
                            abilities, and prepare for the real-world volatility
                            of financial markets.
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            The Practical Process
                          </span>
                          <span>
                            A paper trading account operates with virtual funds.
                            Traders analyze the representation of stocks on a
                            website's chart, note down the ticker, and use this
                            data to make calculated decisions on when to buy or
                            sell the stock.
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            Transitioning to Live Trading
                          </span>
                          <span>
                            Upon mastering their trade strategies on a paper
                            trading platform, traders typically transition to
                            live trading, where actual money is at stake.
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            Advantages and Disadvantages:
                          </span>
                          <div>
                            <span className="font-semibold">Pros:</span>
                            <span>
                              {" "}
                              Paper trading safeguards beginners from risking
                              real money while learning, offers
                              confidence-building through trial and error, and
                              serves as a training ground for emotion management
                              and understanding broader market conditions.
                            </span>
                          </div>
                        </div>
                        <div className="text-left">
                          <span className="font-semibold">Cons:</span>
                          <span className="text-left">
                            {" "}
                            However, paper trading falls short in accounting for
                            various hidden costs encountered in real-world
                            trading and doesn't simulate the unpredictability of
                            the live market. It also can't provide a complete
                            understanding of how specific market conditions can
                            impact individual securities.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-col bg-[#f8fbff] px-10 py-10 rounded-2xl gap-8 min-[800px]:h-[160vh]">
                      <div className="flex flex-col text-left gap-3">
                        <span className="text-[22px] font-semibold">
                          Live Trading
                        </span>
                        <span>Stepping into the Real Market</span>
                      </div>
                      <div className="">
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            The Shift to Real Trading
                          </span>
                          <span>
                            The transition from paper trading to live trading is
                            a significant one. It involves moving from a
                            risk-free simulator to an environment where real
                            money is at stake and subject to the daily
                            fluctuations of the live market
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            Preparing for the Real World
                          </span>
                          <span>
                            An essential part of this transition is remembering
                            the lessons learned during the paper trading phase.
                            Equally vital is the ability to manage emotions.
                            Discipline and emotional control are paramount when
                            trading on platforms with real financial
                            implications, such as Forex.
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            The Role of a Broker
                          </span>
                          <span>
                            A reliable online stock broker can play a crucial
                            role in guiding traders during live trading. From
                            offering investment advice to managing your
                            investment account, a good broker can significantly
                            enhance your live trading experience.
                          </span>
                        </div>
                        <div className="flex flex-col text-left gap-3">
                          <span className="text-[18px] font-semibold">
                            Advantages and Disadvantages:
                          </span>
                          <div>
                            <span className="font-semibold">Pros:</span>
                            <span>
                              {" "}
                              Live trading offers independence in
                              decision-making, the potential for real financial
                              gains, and the opportunity to reap rewards from
                              dividends over time.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="min-[800px]:hidden flex flex-col">
                <div className="flex flex-col min-[800px]:flex-row bg-[#f8fbff] mt-8">
                  <div className="flex flex-col">
                    <div className="flex flex-col bg-[#f8fbff] px-5 py-10 rounded-2xl gap-8">
                      <div className="flex flex-col text-left gap-3">
                        <span className="text-[22px] font-semibold">
                          Paper Trading
                        </span>
                        <span>A Risk-Free Market Simulator</span>
                      </div>
                      <div>
                        <Difference
                          question={"The Purpose of Paper Trading"}
                          answer={
                            "Paper trading serves to mimic real-world trading scenarios without real financial implications. The primary goal of a paper trading account is to provide traders with a safe space to enhance their trading strategies, sharpen their decision-making abilities, and prepare for the real-world volatility of financial markets."
                          }
                        />
                        <Difference
                          question={"The Practical Process"}
                          answer={
                            "A paper trading account operates with virtual funds. Traders analyze the representation of stocks on a website's chart, note down the ticker, and use this data to make calculated decisions on when to buy or sell the stock."
                          }
                        />
                        <Difference
                          question={"Transitioning to Live Trading"}
                          answer={
                            "Upon mastering their trade strategies on a paper trading platform, traders typically transition to live trading, where actual money is at stake."
                          }
                        />
                        <Pro_Con
                          pros={
                            "Paper trading safeguards beginners from risking real money while learning, offers confidence-building through trial and error, and serves as a training ground for emotion management and understanding broader market conditions."
                          }
                          cons={
                            "However, paper trading falls short in accounting for various hidden costs encountered in real-world trading and doesn't simulate the unpredictability of the live market. It also can't provide a complete understanding of how specific market conditions can impact individual securities."
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-col bg-[#f8fbff] px-5 py-10 rounded-2xl gap-8">
                      <div className="flex flex-col text-left gap-3">
                        <span className="text-[22px] font-semibold">
                          Live Trading
                        </span>
                        <span>Stepping into the Real Market</span>
                      </div>
                      <div className="">
                        <Difference
                          question={"The Shift to Real Trading"}
                          answer={
                            "The transition from paper trading to live trading is a significant one. It involves moving from a risk-free simulator to an environment where real money is at stake and subject to the daily fluctuations of the live market."
                          }
                        />
                        <Difference
                          question={"Preparing for the Real World"}
                          answer={
                            "An essential part of this transition is remembering the lessons learned during the paper trading phase. Equally vital is the ability to manage emotions. Discipline and emotional control are paramount when trading on platforms with real financial implications, such as Forex."
                          }
                        />
                        <Difference
                          question={"The Role of a Broker"}
                          answer={
                            "A reliable online stock broker can play a crucial role in guiding traders during live trading. From offering investment advice to managing your investment account, a good broker can significantly enhance your live trading experience."
                          }
                        />
                        <Pro_Con
                          pros={
                            "Live trading offers independence in decision-making, the potential for real financial gains, and the opportunity to reap rewards from dividends over time."
                          }
                          cons={
                            "On the flip side, live trading brings the risk of potential addiction to trading, a high-stress environment due to the need for quick decision-making, and the very real possibility of substantial monetary losses."
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {isDifference ? (
          <div className="h-[1px] w-full bg-gray-200 mt-8" />
        ) : (
          <div />
        )}
      </div>
      <div className="bg-[#00316f] h-[100vh] flex flex-col w-full px-5 min-[800px]:px-20">
        <div className="bg-[#00449b] m-10 min-[800px]:m-20 rounded-2xl py-20 px-5 min-[800px]:px-20">
          <div className="flex flex-col min-[800px]:flex-row gap-14 justify-center items-center">
            <div className="flex flex-col">
              <span className="text-[72px] text-white min-[800px]:text-[100px] font-semibold leading-[74px]">
                4.8
              </span>
              <span className="text-white text-[18px] min-[800px]:text-[28px]">
                playstore ratings
              </span>
              <svg
                className="mt-4 w-full min-[800px]:h-12 h-auto"
                width="271"
                height="47"
                viewBox="0 0 271 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                focusable="false"
                class="md-icon ssD9j"
              >
                <path
                  d="M23.7386 0.105469L28.9743 16.2193H45.9174L32.2102 26.1782L37.4459 42.292L23.7386 32.3331L10.0314 42.292L15.2671 26.1782L1.55983 16.2193H18.5029L23.7386 0.105469Z"
                  fill="white"
                ></path>
                <path
                  d="M79.707 0.105469L84.9427 16.2193H101.886L88.1785 26.1782L93.4142 42.292L79.707 32.3331L65.9998 42.292L71.2355 26.1782L57.5282 16.2193H74.4713L79.707 0.105469Z"
                  fill="white"
                ></path>
                <path
                  d="M135.675 0.105469L140.911 16.2193H157.854L144.147 26.1782L149.383 42.292L135.675 32.3331L121.968 42.292L127.204 26.1782L113.497 16.2193H130.44L135.675 0.105469Z"
                  fill="white"
                ></path>
                <path
                  d="M191.644 0.105469L196.879 16.2193H213.823L200.115 26.1782L205.351 42.292L191.644 32.3331L177.937 42.292L183.172 26.1782L169.465 16.2193H186.408L191.644 0.105469Z"
                  fill="white"
                ></path>
                <path
                  opacity="0.24"
                  d="M247.612 0.105469L252.848 16.2193H269.791L256.084 26.1782L261.319 42.292L247.612 32.3331L233.905 42.292L239.141 26.1782L225.433 16.2193H242.376L247.612 0.105469Z"
                  fill="white"
                ></path>
                <path
                  d="M247.612 0.105469V11.5968C247.612 11.5968 247.612 15.3423 247.612 22.6834V32.3331L233.905 42.292L239.14 26.1782L225.433 16.2193H242.376L247.612 0.105469Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div className="flex flex-col gap-8 justify-center items-center">
              <div
                onClick={openPlayStore}
                className="flex flex-row gap-3 bg-white font-bold justify-center px-4 min-[800px]:px-8 py-2 min-[800px]:py-4 rounded-full items-center"
              >
                <img
                  className="w-4 min-[800px]:w-6 h-auto"
                  src="images/google-play.png"
                />
                <span className="min-[800px]:text-[22px]">Download now</span>
              </div>
              <div
                onClick={openIos}
                className="flex flex-row gap-3 bg-white font-bold justify-center px-4 min-[800px]:px-8 py-2 min-[800px]:py-4 rounded-full items-center"
              >
                <img
                  className="w-4 min-[800px]:w-6 h-auto"
                  src="images/apple-logo.png"
                />
                <span className="min-[800px]:text-[22px]">Download now</span>
              </div>
            </div>
          </div>
        </div>
        {/* <TestimonialSlider /> */}
      </div>
      <div className="flex flex-col px-5 min-[800px]:px-20">
        <div className="flex flex-row justify-between items-center mt-20">
          <span className="text-[18px] min-[800px]:text-[40px] text-left font-semibold">
            Tips for Successful Paper Trading
          </span>
          {isTips ? (
            <div
              className="text-3xl min-[800px]:hidden cursor-pointer"
              onClick={toggleTips}
            >
              +
            </div>
          ) : (
            <div
              className="text-4xl min-[800px]:hidden cursor-pointer pr-1"
              onClick={toggleTips}
            >
              -
            </div>
          )}
        </div>
        {!isTips && (
          <>
            <Question
              question="Trade Like It's Real"
              answer="Just because it's a paper trading account doesn't mean you should treat it lightly. Approach every trade like it's coming out of your actual pocket. This practice instils a discipline that's crucial when you transition to live trading"
            />
            <Question
              question="Experiment and Perfect Your Trading Strategies"
              answer="Utilize our paper trading platform to experiment with different trading strategies. The stock market is a dynamic beast - your strategies need to adapt too. Continuously analyze, tweak, and optimize your strategies for the best results."
            />
            <Question
              question="Maintain a Trade Journal"
              answer="One of the best paper trading tips is to keep a record of your trades. This will help you spot patterns, identify strengths and weaknesses, and give you a clear picture of your trading habits. It's all about self-improvement and strategy optimization."
            />
            <Question
              question="Utilize Updated Market Data"
              answer="With our paper trading simulator, you get frequently updated market data. Use this to your advantage to understand how real-time changes affect your trades. It's the closest thing to trading in a live market environment!"
            />
            <Question
              question="Practice Across Market Conditions"
              answer="Don't limit your practice to bullish markets. Venture into bearish and volatile market scenarios as well. Our paper trading platform allows you to experience the roller coaster of market conditions, preparing you for real-life trading dynamics."
            />
            <Question
              question="Keep Learning and Evolving"
              answer="Whether you're a novice trader or a seasoned pro, paper trading serves as an excellent learning tool. With our platform, you can continually test new strategies, explore different markets, and enhance your trading skills. The world of trading never stands still, and neither should you!"
            />
            <Question
              question="Why is paper trading useful for beginners?"
              answer="Think of paper trading as a playground for beginners. It's like a sandbox where you can experiment with trading strategies, understand market dynamics, and monitor the impact of news on stocks. And the best part? You get to do all of this with virtual money. This way, you get to practice, make mistakes, learn, and gain confidence before you dive into real trading."
            />
          </>
        )}
      </div>

      {isTips ? <div className="h-[1px] w-full bg-gray-200 mt-8" /> : <div />}

      <div className="flex flex-col px-5 py-10 min-[800px]:px-20 bg-[#090b34] text-white">
        <div className="flex flex-col min-[800px]:flex-row items-center min-[800px]:justify-between pb-10 gap-10">
          <div className="flex flex-row gap-6 justify-center items-center">
            <div className="flex flex-col min-[800px]:flex-row justify-center items-center gap-3">
              <span className="text-[24px] font-semibold">Sauda Pro</span>
              <div className="flex flex-row gap-3">
                <div
                  onClick={openIos}
                  className="flex flex-row gap-3 bg-white font-bold justify-center px-4 min-[800px]:px-6 py-2 min-[800px]:py-4 rounded-2xl items-center"
                >
                  <img
                    className="w-4 min-[800px]:w-6 h-auto"
                    src="images/apple-logo.png"
                  />
                  <span className="min-[800px]:text-[18px] text-black">
                    Appstore
                  </span>
                </div>
                <div
                  onClick={openPlayStore}
                  className="flex flex-row gap-3 bg-white font-bold justify-center px-4 min-[800px]:px-6 py-2 min-[800px]:py-4 rounded-2xl items-center"
                >
                  <img
                    className="w-4 min-[800px]:w-6 h-auto"
                    src="images/google-play.png"
                  />
                  <span className="min-[800px]:text-[18px] text-black">
                    Playstore
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="block min-[800px]:hidden">
              <span className="text-[18px]">Follow us on</span>
            </div>
            <div className="flex flex-row gap-5">
              <div onClick={openX} className="bg-white rounded-full p-3">
                <img className="w-10 h-10" src="images/twitter-logo.png" />
              </div>
              <div onClick={openFacebook} className="bg-white rounded-full p-3">
                <img className="w-10 h-10" src="images/facebook-logo.png" />
              </div>
              <div onClick={openInstagram} className="bg-white rounded-full p-3">
                <img className="w-10 h-10" src="images/instagram-logo.png" />
              </div>
              <div onClick={openYoutube} className="bg-white rounded-full p-3">
                <img className="w-10 h-10" src="images/youtube-logo.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#191b4b] flex flex-col gap-4 p-5 rounded-[20px] text-left">
          <span className="font-bold">FINANCIAL CONTENT DISCLAIMER</span>
          <span>
            The content provided on Sauda Pro is for informational purposes only
            and does not constitute financial advice, endorsement, analysis, or
            recommendations. The content is primarily user-generated and
            reflects the personal opinions and experiences of Sauda Pro
            community members. We strongly advise our users to conduct their own
            research or consult with a qualified financial advisor before making
            any financial decisions. Sauda Pro does not guarantee the accuracy,
            timeliness, or completeness of any information provided by our users
            and will not be held responsible for any decision made based on such
            information. It's essential for users to approach financial
            discussions and decisions with caution and skepticism. Avoid any
            financial strategies or investments that promise unrealistically
            high returns or sound too good to be true. Always ensure that any
            financial decision aligns with your personal financial situation,
            goals, and risk tolerance. We do not assume any liability with
            respect to any loss or damage, arising directly or indirectly due to
            any inaccuracy or incompleteness of any information or a breach of
            any warranty or guaranty due to the actions of any user of the
            Platform. Please read our Content Policy and Terms of Use to
            understand more.
          </span>
        </div>
        <div className="flex flex-col gap-4 p-3 text-left text-[12px]">
          <span className="font-bold text-[14px]">ABOUT Sauda Pro</span>
          <span>
            India’s #1 community of share market investors and traders: Find out
            what real share market investors and traders are saying about your
            stocks right now. Sauda Pro app has stock specific forums where you
            can discuss your existing trades or your planned future trade with
            fellow stock market traders. Socialize your trading idea to get more
            ideas or signals that you might have missed. Discuss outlook in
            stock specific forums and keep a tab on news that is moving the
            Indian stock & commodities markets.
          </span>
        </div>
        <div className="flex flex-col gap-4 p-3 text-left text-[12px]">
          <span className="font-bold text-[14px]">TOOLS</span>
          <span>
            Paper Trade | Nifty 50 Heatmap | Nifty Bank Heatmap | FII & DII |
            NSE Option Chain |{" "}
          </span>
        </div>
        <div className="flex flex-col gap-4 p-3 text-left text-[12px]">
          <span className="font-bold text-[14px]">
            POPULAR STOCKS & COMMODITIES (MCX) DISCUSSION COMMUNITIES
          </span>
          <span>
            IDX:NIFTY BANK | IDX:NIFTY 50 | PNB | YESBANK | DHFL | TATAMOTORS |
            INFY | TCS | HDFCBANK | RELIANCE | SBIN | ASHOKLEY | BAJFINANCE |
            DLF | IBULHSGFIN | ICICIBANK | ITC | LT | MARUTI | ONGC | PCJEWELLER
            | SUNPHARMA | SUZLON | TATASTEEL | TITAN | UPL | WIPRO | ZEEL |
            MCX:CRUDEOIL | MCX:GOLD | MCX:COPPER | MCX:ZINC | MCX:NICKEL |
            MCX:NATURALGAS | MCX:CPO | MCX:MENTHAOIL |{" "}
          </span>
        </div>
        <div className="flex flex-col gap-4 p-3 text-left text-[12px]">
          <span className="font-bold text-[14px]">SHARE PRICE SCANS</span>
          <span>
            NSE TOP Gainers Today | NSE TOP Losers Today | 52 Week High Stocks |
            52 Week Low Stocks | Near 52 Week High Stocks | Near 52 Week Low
            Stocks |{" "}
          </span>
        </div>
        <div className="flex flex-col gap-4 p-3 text-left text-[12px]">
          <span className="font-bold text-[14px]">
            STOCK COMMUNITIES LIST BY SECTOR
          </span>
          <span>
            Agriculture/Horticulture/Lives | Auto | Auto Ancillaries | Banks |
            Beverages | Cables | Cement | Chemicals | Construction | Consumer
            Durables | Edible Fat | Electric Equipment | Electronics |
            Engineering | Fertilisers | Finance | Food Processing | Gems and
            Jewellery | Glass | Hospitality | Information Technology |
            Infrastructure | Iron and Steel | Leather | Media | Metals - Non
            Ferrous | Mining | Miscellaneous | Packaging | Paints and Pigments |
            Paper | Personal Care | Petrochemicals | Petroleum | Pharmaceuticals
            and health care | Plastics | Power | Retail | Rubber Products |
            Services | Shipping | Sugar | Tea / Coffee | Telecommunications |
            Textiles | Tobacco | Transport | Tyres |{" "}
          </span>
        </div>
      </div>
      <div className="bg-[#090b34] flex flex-col gap-6 pb-20">
        <div className="h-[1px] w-full bg-gray-600" />
        <div className="flex flex-row justify-center items-center gap-3 text-white">
          <span className="cursor-pointer" onClick={Navigate("/Help")}>
            Help
          </span>
          <span className="cursor-pointer" onClick={() => Navigate("/About")}>
            About
          </span>
          <span className="cursor-pointer" onClick={() => Navigate("/Privacy")}>
            Privacy
          </span>
          <span className="cursor-pointer" onClick={() => Navigate("/Terms")}>
            Terms
          </span>
          <span className="cursor-pointer" onClick={() => Navigate("/Content")}>
            Content Policy
          </span>
        </div>
        <div className=" text-white text-[12px]">
          © SaudaPro Private Limited All rights reserved. Made with ❤️ in
          Bangalore. For queries, feedback or suggestions please email us at
          contact@sauda.io
        </div>
      </div>
    </div>
  );
};

export default Home;
