import React, { useState } from 'react';

const Learn = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full mx-auto text-left">
            <div className="bg-white rounded mx-10 py-5 border-b-[1px] border-gray-200">
                <div className='w-full flex flex-row justify-between items-center'
                    onClick={toggleOpen}>
                    <div className='flex flex-row gap-2'>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false" class="md-icon VHy7A noFill mr-15"><g clip-path="url(#clip0_187_2651)"><path d="M8.25 12.7607L10.5 15.0107L15.75 9.76074" stroke="#157BFB" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 21.0107C16.9706 21.0107 21 16.9813 21 12.0107C21 7.04018 16.9706 3.01074 12 3.01074C7.02944 3.01074 3 7.04018 3 12.0107C3 16.9813 7.02944 21.0107 12 21.0107Z" stroke="#157BFB" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_187_2651"><rect width="24" height="24" fill="white" transform="translate(0 0.0107422)"></rect></clipPath></defs></svg>
                        <h1
                            className="font-semibold text-[14px] mb-2 cursor-pointer"
                        >
                            {question}
                        </h1>
                    </div>
                    {
                        !isOpen ? <img className='w-5 h-5' src='images/down.png' />
                            : <img className='w-5 h-5' src='images/up.png' />
                    }
                </div>
                {isOpen && (
                    <p className="mt-3 text-gray-700 text-[12px]">
                        {answer}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Learn;